<template>
  <div>
    <div id="refund-page" class="flex-column">
      <div class="flex-center width-1000 body-2">
        <div>
          <h1>Cancellation & Refund policy</h1>
        </div>

        <div class="spacing">
          <h3>Cancellation Policy</h3>
          <p>
            iAppraise works on a completely customer centric approach and we believe in working towards your happiness.
            To achieve this, we have a very simple cancellation policy. The process to initiate a cancelation request is
            by sending an email to
            <a href="mailto:support@iappraise.net.au">support@iappraise.net.au</a>. You will immediately receive a
            confirmation from your account manager with the date of cancellation. Yes, it is as simple as it sounds. Our
            official policy on cancellation stands as follows:
          </p>
          <ul>
            <li>The customer is solely responsible for cancelling their account.</li>
            <li>
              All the data stored with iAppraise will be archived immediately from all our servers but will be stored
              with us for future use. We guarantee your privacy will not be compromised in any case.
            </li>
            <li>
              If the customer chooses to cancel their service during a month that they have already paid for in advance,
              the cancelation will take effect at the end of this one month period regardless of what day of the month
              it was cancelled. iAppraise will not charge your account after that one month period.
            </li>
          </ul>
          <p>
            iAppraise, in its sole discretion, has the right to suspend or terminate your account and refuse any and all
            current or future use of the Service. This will be done if the customer is involved in any abusive behavior,
            tries to access any of our portals or servers or databases without authorization. We may also suspend or
            terminate your account in any case of data theft, or using our service for a purpose other than its intent.
            iAppraise will deactivate your account and also your access to your PC support services. iAppraise may also
            terminate your service and cancel your account if the customer uses any software which is illegal.
          </p>
          <p>iAppraise reserves the right to refuse service to any customer under various circumstances at any time.</p>

          <h3>Refund Policy</h3>
          <p>
            iAppraise offers subscriptions to be paid for monthly in advance only. If a cancellation request occurs
            during the term of your monthly paid date period you will not be able to receive a refund for that period,
            rather your account will stop being charged at the end of that one month cycle from the date you activated
            the service.
          </p>
          <p>
            The services & support iAppraise provide may not be successful because the problem you are experiencing may
            be beyond our ability to resolve remotely or and/or may be due to an irreparable software and/or hardware
            issue at the user end. If for any reason you are not wholly satisfied with the service, we will make
            commercially reasonable efforts to resolve the issue to your satisfaction.
          </p>
          <p>
            Refunds are only processed after receiving a request via email from the registered email address in our
            records. Please send your email to
            <a href="mailto:support@iappraise.net.au">support@iappraise.net.au</a>. We do not process requests over chat
            and phone.
          </p>

          <h3>Terms which may void the refund request are:</h3>
          <ol>
            <li>
              If you are requesting for a refund from an email address which isn't the registered email address of the
              user account.
            </li>
            <li>
              If you are requesting for a refund to a credit card which wasn't the same as the one which you used to
              process payment while placing an order for your subscription.
            </li>
            <li>If you have placed a request by any medium besides email.</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RefundPolicy"
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
@import "@/scss/v2/legal.scss";

#refund-page {
  padding-top: $PADDING_X2_05;
}
</style>
